.V_card-list{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;
    font-family: "Schoolbell";
    font-weight:bold;
    font-style: normal;
    font-size: 20px;
    
    background-size: cover;
}
.V_card_container_div{
    height:fit-content;
    width: 100%;
    /* background-color: rgb(245, 247, 126); */
    background-color: #07dbdb31;
    /* margin-bottom: -10rem; */
    padding-top: 2rem;
    padding-bottom: 2rem;
}
.V_main_div{
    width: 90%;
    /* background: rgb(239, 144, 222); */
    margin: auto;
    border-radius: 10px;
}
.V_Heading_div{
    width: 100%;
    height:20vh;
    margin-top: -38px;
    background-size: cover;
}
.V_heading{
    font-size: 60px;
    font-family: "Montserrat", sans-serif;
    font-weight:bold;
    font-style: normal;
    /* font-size: 50px; */
    text-align: center;
    padding: 30px;
    color: black;
    /* margin-top: 60px; */
}



.youtube-gallery {
    display: flex;
    flex-wrap: wrap;
    gap: 10px; /* Adjust the gap between videos as needed */
    justify-content: center; /* Center align the items */
  }
  
  .youtube-item {
    flex: 1 1 calc(25% - 10px); /* 4 items per row, with gap adjustment */
    box-sizing: border-box;
    position: relative;
    overflow: hidden;
    background: #000; /* Background color for the iframe container */
  }
  
  .youtube-item iframe {
    width: 100%;
    height: 100%;
  }
  
  /* Responsive adjustments */
  @media (max-width: 1200px) {
    .youtube-item {
      flex: 1 1 calc(33.33% - 10px); /* 3 items per row on medium screens */
    }
  }
  
  @media (max-width: 768px) {
    .youtube-item {
      flex: 1 1 calc(50% - 10px); /* 2 items per row on small screens */
    }
  }
  
  @media (max-width: 480px) {
    .youtube-item {
      flex: 1 1 100%; /* 1 item per row on very small screens */
    }
  }
  

.privacy-policy-main-div{
    background-color: #09e8e81d;
    margin-top: 5%;
}
.privacy-policy-container {
    padding: 20px;
    margin: 0 auto;
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    max-width: 90%;
}

/* Title */
.privacy-policy-title {
    text-align: center;
    margin-bottom: 20px;
    font-size: 3rem;
    color: #970d99;
    padding: 10px;
    font-family: "Montserrat", sans-serif !important;
}

/* Headings */
.privacy-policy-heading {
    margin-top: 20px;
    font-size: 1.8rem;
    color: #970d99;
    padding: 10px;
    font-family: "Montserrat", sans-serif !important;
}

/* Text */
.privacy-policy-text {
    margin: 10px 0;
    line-height: 1.6;
    color: #666;
    padding: 10px;
    font-family: "Montserrat", sans-serif !important;
}

/* Section */
.privacy-policy-section {
    margin-bottom: 20px;
}

@media (max-width: 768px) {
    .privacy-policy-title {
        font-size: 2.5rem; /* Smaller font size for smaller screens */
    }
    
    .privacy-policy-heading {
        font-size: 1.5rem; /* Adjust heading size for tablets */
    }
    
    .privacy-policy-text {
        font-size: 0.9rem; /* Adjust paragraph font size */
    }
    
    .privacy-policy-container {
        padding: 15px; /* Reduce padding for smaller screens */
    }
    .privacy-policy-main-div{
        margin-top: 10%;
    }

}

/* Extra small devices (max-width: 480px) */
@media (max-width: 480px) {
    .privacy-policy-title {
        font-size: 1.8rem; /* Further reduced font size for mobile */
    }

    .privacy-policy-heading {
        font-size: 1.3rem;
    }

    .privacy-policy-text {
        font-size: 0.85rem;
    }
    
    .privacy-policy-container {
        padding: 10px; /* Smaller padding for mobile */
    }
    .privacy-policy-main-div{
        margin-top: 20%;
    }
}

.blog-section {
    text-align: center;
    padding: 40px 20px;
    height: auto;
    background-color: #09e8e81d;
    margin-top: 5%;
  }
  
  .blog-section h1 {
    font-size: 60px;
    color: #970d99;
    margin-bottom: 20px;
    font-family: "Montserrat", sans-serif;
  }
  
  .blog-container {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 20px;
  }
  
  .blog-card {
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease;
    overflow: hidden;
    width: 300px;
  }
  
  .blog-card:hover {
    transform: translateY(-10px);
  }
  
  .blog-image {
    width: 100%;
    height: 200px;
    object-fit: cover;
  }
  
  .blog-card h2 {
    margin: 15px 10px;
    font-size: 1.25rem;
    color: #333;
    font-family: "Montserrat", sans-serif;
  }
  
  .blog-card h2 a {
    text-decoration: none;
    color: #07dbdb;
    transition: color 0.3s;
  }
  
  .blog-card h2 a:hover {
    color: #333;
  }

  @media (max-width: 768px) {
.blog-section{
  margin-top: 8%;
}
.blog-section h1{
  font-size: 2.5rem;
}
  }
  
  @media (max-width: 480px) {
    .blog-section{
      margin-top: 15%;
    }
    .blog-section h1{
      font-size: 2.4rem;
    }
      }
.About_fourth_main_div {
    height: auto;
    width: 100%;
    margin-top: 5%;
    background-color: #0adada1f;
    padding: 2%;
}

.about_fourth_heading {
    font-size: 65px;
    font-weight: bold;
    text-align: center;
    margin-top: -30px;
    color: #970d99f0;
    padding: 30px;
    /* padding-bottom: 30px; */
    font-family: "Montserrat", sans-serif;
}

.about_fourth_div {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    column-gap: 10px;
    row-gap: 20px; /* Added gap between rows */
}
.about4button{
    /* border: 2px solid red; */
    text-align: left;
    width: 60%;
    margin-left: 18%;
}
.about_fourth_div1 {
    flex-basis: 30%;
    height: auto;
    text-align: center;
}

.About_fourth_img_div {
    width: 100%;
    text-align: center;
}

.about_fourth_pic {
    height: 250px;
    width: 250px;
    /* object-fit: cover;  */
}
.about_fourth_pic1 {
    height: 250px;
    width: auto;
    margin-left: 5%;
    /* object-fit: cover;  */
}
.about_fourth_para1 {
    color: #196fcc;
    width: 70%;
    margin-left: 15%;
    justify-content: center;
    line-height: 22px;
    font-size: 14px;
    margin-top: 15px;
    padding-left: 10px;
    font-family: "Montserrat", sans-serif;
}

.about_fourth_h3 {
    font-size: 24px;
    color: #970d99f0;
    margin-bottom: 10px;
    font-family: "Montserrat", sans-serif;
}

.button2 {
    display: inline-block;
    transition: all 0.2s ease-in;
    position: relative;
    overflow: hidden;
    z-index: 1;
    font-family: "Montserrat", sans-serif;
    color: #970d99f0;
    padding: 0.7em 1.7em;
    cursor: pointer;
    margin-top: 10px;
    font-size: 11px;
    border-radius: 0.5em;
    background: #e8e8e8;
    border: 1px solid #e8e8e8;
    box-shadow: 6px 6px 12px #c5c5c5, -6px -6px 12px #ffffff;
}

.button2:hover {
    color: #ffffff;
    background: #c513c8c1;
    border: 1px solid #9b5de5;
}

@media (max-width: 1200px) {
    .about_fourth_heading {
        font-size: 55px;
    }

    .about_fourth_div1 {
        flex-basis: 45%; /* Make cards take more space */
    }

    .about_fourth_para1 {
        font-size: 15px;
    }
}

@media (max-width: 780px) {
    .About_fourth_main_div {
        height: auto;
        width: 100%;
        margin-top: 5%;
    }

    .about_fourth_heading {
        font-size: 50px;
    }

    .about_fourth_div1 {
        flex-basis: 45%;
    }

    .about_fourth_pic {
        height: 220px;
        width: 220px;
    }

    .about_fourth_h3 {
        font-size: 20px;
    }

    .about_fourth_para1 {
        font-size: 14px;
    }
}

@media (max-width: 450px) {
    .About_fourth_main_div {
        margin-top: 45%;
    }

    .about_fourth_heading {
        font-size: 35px;
        margin-top: 2%;
    }

    .about_fourth_div1 {
        flex-basis: 100%;
        text-align: center;
    }
    .about_fourth_div {
       margin-bottom: 10%;
    }
    .about_fourth_pic {
        height: 200px;
        width: 200px;
    }

    .about_fourth_para1 {
        font-size: 14px;
        padding-left: 5%;
    }

    .about_fourth_h3 {
        font-size: 16px;
    }
}

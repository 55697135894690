
.gallery-section {
    text-align: center;
    height: auto;
    background-color: white;
    margin-top: 5%;
  }
  
  .gallery-section h1 {
    font-size: 3rem;
    padding: 40px;
    color: white;
    background-color: #1e8894;
    margin-bottom: 5px;
    font-family: "Montserrat", sans-serif;
  }
  
  .gallery-container {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 5px;
  }
  
  .gallery-card {
    background-color: #1e8894;
    transition: transform 0.3s ease;
    overflow: hidden;
    width: 33%;
    height: 400px;
    display: flex;
    align-items: center;
    justify-content: center ;
  }
  

  .gallery-image {
    width: 50%;
  }
  
  .gallery-card h2 {
    margin: 15px 10px;
    font-size: 1.25rem;
    color: #333;
    font-family: "Montserrat", sans-serif;
  }
  
  .gallery-card h2 a {
    text-decoration: none;
    color: #07dbdb;
    transition: color 0.3s;
  }
  
  .gallery-card h2 a:hover {
    color: #333;
  }
 @media (max-width: 768px) {
  .gallery-section{
    margin-top: 10%;
  }
  .gallery-section h1 {
    font-size: 2.5rem;
  }
  .gallery-card {
    width: 49%;
  }
 }

 @media (max-width: 480px) {
  .gallery-section{
    margin-top: 20%;
  }
  .gallery-section h1 {
    font-size: 2rem;
  }
  .gallery-card {
    width: 100%;
  }
 }
/* Global Reset */
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  .button_div1{
    /* border:solid 2px; */
    width: 100%;
    margin-left: 0px;
    text-align: center;
    padding-bottom: 20px;

  }
  /* Body */
  body {
    font-family: "Montserrat", sans-serif;
  }
  
  /* Heading Courses */
  .heading_courses {
    color: #970d99f0;
    font-family: "Montserrat", sans-serif;
    text-align: center;
    font-size: 3rem; /* Adjusted for better responsiveness */
    padding-top: 3%;
  }
  
  /* Background Color for Courses Section */
  .courses_background_color {
    width: 100%;
    background-color: #09e8e81d;
    padding: 20px; /* Added padding for better spacing */
    margin-top: 3%;
  }
  
  /* Button */
  .submit-btn2 {
    background-color: #970d99f0 !important;
    color: white !important;
    border: none;
    padding: 0.75rem 1.5rem;
    font-size: 1rem !important;
    border-radius: 4px;
    cursor: pointer;
    font-family: "Montserrat", sans-serif;
    font-weight: 700;
    transition: background-color 0.3s, color 0.3s;
  }
  
  .submit-btn2:hover {
    background-color: #07dbdb;
    color: white;
  }
  
  /* Images Section */
  .courses_images_main {
    display: flex;
    flex-wrap: wrap; /* Allow wrapping for smaller screens */
    gap: 20px;
    justify-content: center;
    margin-top: 20px;
  }
  
  .courses_images_div1 {
    width: 100%; /* Full width on small screens */
    max-width: 300px; /* Limit max width for larger screens */
    height: auto;
    margin-top: 0;
  }
  
  .courseimg1 {
    width: 100%;
    height: auto;
    border-radius: 8px; /* Optional: for rounded corners */
  }
  
  /* Paragraph and Arrow Icons */
  .courses_paragraph {
    color: #970d99f0;
    font-size: 1.25rem; /* Adjusted for better responsiveness */
    font-family: "Montserrat", sans-serif;
  }
  
  .arrowicons {
    font-size: 1.5rem;
    color: #970d99f0;
    transition: color 0.3s, transform 0.3s;
  }
  
  /* Hover Effects */
  .courses_images_div1:hover .courses_paragraph {
    color: orangered;
  }
  
  .courses_images_div1:hover .arrowicons {
    color: orangered;
    transform: translateX(10px); /* Slight adjustment for hover effect */
  }
  
  /* Media Queries for Responsive Design */
  @media (max-width: 1024px) {
    .heading_courses {
      font-size: 2.5rem; /* Adjusted for medium screens */
    }
  
    .courses_paragraph {
      font-size: 1.1rem; /* Adjusted for medium screens */
    }
  
    .courses_images_div1 {
      width: calc(50% - 20px); /* 2 items per row */
    }
  }
  
  @media (max-width: 768px) {
    .heading_courses {
      font-size: 2rem; /* Adjusted for small screens */
    }
  
    .courses_paragraph {
      font-size: 1rem; /* Adjusted for small screens */
    }
  
    .courses_images_div1 {
      width: calc(100% - 20px); /* 1 item per row */
    }
    .courses_background_color {
      margin-top: 8%;
    }
  }
  
  @media (max-width: 480px) {
    .submit-btn2 {
      font-size: 0.875rem; /* Adjusted for very small screens */
      padding: 0.5rem 1rem;
    }
  
    .arrowicons {
      font-size: 1.25rem; /* Adjusted for very small screens */
    }
    .courses_background_color {
      margin-top: 20%;
    }
  }
  
/* 2----------------------------------------------------- */
*{
    font-family: Helvetica, Arial, sans-serif;
}
.About_Second_main_div{
    height: auto;
    width: 100%;
/* border: 2px solid; */
display: flex;
margin-top: 2%;
padding-top: 2%;
background-color:#ebffff; 
}
.About_second_first_div{
    height: auto;
    width: 50%;
    /* border: 2px solid blue; */
}
.About_second_second_div{
    height: auto;
    width: 50%;
    margin-bottom:50px ;
    /* border: 2px solid blue;  */
}
.second_main1{
    height: auto;
    width: 100%;
    margin-left: -100px;

    /* border: 2px solid green; */
    display: flex;
}
.second_main2{
    height: auto;
    margin-left: -100px;
    width: 100%;
    /* border: 2px solid green; */
    display: flex;
}
.second_main1_div{
    height: auto;
width: 50%;
font-size: 25px;
color: #970d99f0;
/* text-align: center; */
margin-right: -100px;
font-weight: bold;
padding-top: 5%;
font-family: "Montserrat", sans-serif;
/* border: 2px solid orange; */
}
.second_main1_div2{
    padding-top: 5%;
    height: auto;
width: 70%;
/* border: 2px solid orange; */
font-size: 15px;
}
.content_para1{
    height: auto;
    width: 100%;
    font-size: 18px;
    line-height: 25px;
    color:#196fcc;
    font-family: "Montserrat", sans-serif;
    /* border: 1px solid; */
}
.about_second_image{
    height: 90vh;
    margin-left: 5%;
    width:auto;
    /* border: 2px solid; */
    padding: 10px;
}

.button-84 {
  --b: 3px;   /* border thickness */
  --s: .45em; /* size of the corner */
  --color: #970d99f0;
  
  padding: calc(.5em + var(--s)) calc(.9em + var(--s));
  color: var(--color);
  --_p: var(--s);
  background:
    conic-gradient(from 90deg at var(--b) var(--b),#0000 90deg,var(--color) 0)
    var(--_p) var(--_p)/calc(100% - var(--b) - 2*var(--_p)) calc(100% - var(--b) - 2*var(--_p));
  transition: .3s linear, color 0s, background-color 0s;
  outline: var(--b) solid #0000;
  outline-offset: .6em;
  font-size: 16px;
  font-family: "Montserrat", sans-serif;
  border: 0;
 margin-left: -300px;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}
.text a{
  text-decoration: none;
  color: white;
}
.button-84:hover,
.button-84:focus-visible{
  --_p: 0px;
  outline-color: var(--color);
  outline-offset: .05em;
}
.button_div1{
  margin-top: 20px;
  margin-left: 260px ;
}
.button-84:active {
  background: var(--color);
  color: #fff;
}
/* From Uiverse.io by ryota1231 */ 
.animated-button {
    position: relative;
    display: flex;
    align-items: center;
    /* margin-left: 50px; */
    text-align: left;
    gap: 4px;
    padding: 10px 36px;
    border: 4px solid;
    margin-top: -50px;
    border-color: transparent;
    font-size: 12x;
    background-color: #970d99f0;
    border-radius: 100px;
    font-weight: 600;
    color: white;
    box-shadow: 0 0 0 2px #ffffff;
    cursor: pointer;
    overflow: hidden;
    font-family: "Montserrat", sans-serif;
    transition: all 0.6s cubic-bezier(0.23, 1, 0.32, 1);
    margin-left: 500px;
  }
  
  .animated-button svg {
    position: absolute;
    width: 24px;
    fill: white;
    z-index: 9;
    transition: all 0.8s cubic-bezier(0.23, 1, 0.32, 1);
  }
  
  .animated-button .arr-1 {
    right: 16px;
  }
  
  .animated-button .arr-2 {
    left: -25%;
  }
  
  .animated-button .circle {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 20px;
    height: 20px;
    /* background-color: white; */
    border-radius: 50%;
    opacity: 0;
    transition: all 0.8s cubic-bezier(0.23, 1, 0.32, 1);
  }
  
  .animated-button .text {
    position: relative;
    z-index: 1;
    transform: translateX(-12px);
    transition: all 0.8s cubic-bezier(0.23, 1, 0.32, 1);
  }
  
  .animated-button:hover {
    box-shadow: 0 0 0 12px transparent;
    color: black !important;
    /* border-radius: 12px; */
    background-color: white;
  }
  
  .animated-button:hover .arr-1 {
    right: -25%;
  }
  
  .animated-button:hover .arr-2 {
    left: 16px;
  }
  
  .animated-button:hover .text {
    transform: translateX(12px);
  }
  
  .animated-button:hover svg {
    fill: purple;
  }
  
  .animated-button:active {
    scale: 0.95;
    /* box-shadow: 0 0 0 4px greenyellow; */
  }
  
  .animated-button:hover .circle {
    width: 220px;
    height: 220px;
    opacity: 1;
  }
  
@media (max-width: 1200px) {
    .About_second_first_div,
    .About_second_second_div {
        width: 100%;
    }
    .button-84{
      font-size: 15px;
      margin-left: 10px;
    }
    .animated-button{
      font-size: 15px;
      margin-left: -40px;
    }
    .second_main1_div,
    .second_main1_div2 {
        width: 100%;
    }
    .about_second_image{
      height: 70vh;
      margin-left: 20px;
      /* border: solid 2px; */
      margin-top: 20px;
    }
    .second_main1,
    .second_main2 {
        flex-direction: column;
    }

    .second_main1_div {
        width: 100%;
        font-size: 14px;
    }
    .content_para1{
      font-size: 15px;
    }
    .second_main1_div2 {
        width: 100%;
        font-size: 10px;
    }
}
@media (max-width: 780px) {
    .About_Second_main_div {
        flex-direction: column;
    }
    /* .button-84{
      font-size: 15px;
     border: 2px solid;
     margin-left:20px;
    } */
    .animated-button{
      height: 30px;
      margin-top: 10px;
      /* font-size: 15px; */
      /* justify-content: left; */

    }
    .button_div1{
      display: flex;
      justify-content: space-between;

    }
    .About_second_first_div,
    .About_second_second_div {
        width: 100%;
        padding: 10px;
        margin-bottom: 20px;
        /* justify-content: center; */
        /* justify-items: center; */
        /* border: #196fcc solid 2px; */
        text-align: center;
    }
    .content_para1{
      font-size: 15px !important;
      /* padding: 4px; */
      
    }
    .second_main1_div {
        width: 100%;
        font-size: 13px;
        margin-left: 100px;
        /* border: #196fcc solid 2px; */
    }

    .second_main1_div2 {
        width: 100%;
        font-size: 13px;
        margin-left: 100px;
                /* border: #196fcc solid 2px; */

    }

    .about_second_image {
        height: 80vh;
        padding: 20px;
        width: 70%;   

    }
}

@media (max-width: 576px) {
    .About_Second_main_div{
        margin-top: 10%;
    } 
    .second_main1_div {
        font-size: 23px;
    }

    .second_main1_div2 {
        font-size: 12px;
        width: 90%;
        padding-left: 10%;
    }

    .about_second_image {
        height: auto;
        width: 100%;
        /* padding: 5px; */

    }
    .button-84{
      font-size: 10px;
      margin-left: 20px;
      margin-top: 10px;
    }
    .text a{
      text-decoration: none;
      color: white;
      font-size: 10px;
      
    }
    .animated-button {
   
      margin-right: 30px;
      padding: 10px 30px !important;
      
    }
    #DISCOVER{
      font-size: 12px !important;
    }
   
   
}
.admission-form-container {
    background-color: white;
    padding: 3rem;
    height: auto;
    background-size: cover;
    background-position: -330px; 
    margin-top: 5%;   
}

.form_content{
    display:flex;
    padding:5%;
    gap:5%;
    width:80%;
    margin:auto;
    /* background-color:#82f1f16d!important; */
    background-color: #09e8e81d !important;
    background:transparent;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    border:2px solid #61baba6d;
    border-radius:2%;
}

.Admission_div {
    text-align: center;
    margin-bottom: 1rem;
}

.Admission_Lion {
    width: 100px;
    height: 400px;
    margin-right: 10%;
    
}
.div2_form{
    width: 50%;
}
.div1_form{
    width: 50%;
}
.Admission_h {
    font-size: 4rem;
    margin-right: 50%;

}
.admission_h1{
    color: #970d99;
    font-family: "Montserrat", sans-serif;
    font-size: 3rem;
    margin-left: 10%;
    margin-top: -10px;
    padding-bottom: 30px;
}

.a_form_header {
    display: flex;
    align-items: center;
    margin-bottom: 2rem;
    background-color: rgb(77, 85, 234);
}

.A_img {
    margin-right: 1rem;
    height: 70px;
}

.Admission_logo {
    width: 80px;
    height: auto;
}

.Admission_name {
    font-size: 1.5rem;
    margin: 0;
}

.form {
    display: flex;
    flex-direction: column;
}

.form-grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 0.8rem;
}
.select_admission{
    margin-top: -15px;
    border: 1px solid rgb(230, 28, 230);
    border-radius: 6px;
}
.label {
    display: flex;
    flex-direction: column;
    margin-bottom: 0.8rem;
    color:#0f7272e7;
    font-weight:bold;
    font-size: 16px;
    font-family: "Montserrat", sans-serif;
    /* margin: 5px; */
    
}
.textarea::placeholder {
    font-size: 14px; 
}
.input,
.textarea {
    padding: 0.5rem;
    
    border: 1px solid rgb(230, 28, 230);
    border-radius: 4px;
    color: purple;
    font-size: 1rem;
    font-family: "Montserrat", sans-serif;
    margin-bottom: 10px;
    width: 100%;


}

.textarea {
    resize: vertical;
}

/* .checkbox {
   margin-right: 15%;
} */

/* .submit-btn {
    background-color: #a841c0;
    color: white;
    border: none;
    padding: 0.75rem 1.5rem;
    font-size: 1rem;
    border-radius: 4px;
    cursor: pointer;
    align-self: flex-start;
    font-family: "Montserrat", sans-serif;
    margin-top: 7px;
} */

.submit-btn {
    background-color: #a841c0;
    color: white;
    border: none;
    padding: 0.5rem 0.5rem;
    font-size: 1rem;
    border-radius: 4px;
    cursor: pointer;
    align-self: flex-start;
    font-family: "Montserrat", sans-serif;
    margin-top: 10px;
    /* justify-content: right;
    margin-left: 70%; */
    font-size: 12px;
}

.input,
.textarea {
    border: 1px solid rgb(230, 28, 230);
  border-radius: 4px; /* Optional: Rounded corners */
  padding: 10px; /* Padding */
  transition: border-color 0.3s; /* Smooth transition */
}

.input:focus,
.textarea:focus {
  border-color: rgb(216, 56, 216); /* Focus color */
  outline: none; /* Remove default outline */
}


.courses_label{
    font-family: "Montserrat", sans-serif;
}
.submit-btn:hover {
    background-color: #0056b3;
}

@media (max-width: 1024px) {
    .admission_h1{
        /* color: black; */
        font-family: "Montserrat", sans-serif;
        font-size: 60px;
        margin-left: 5%;
        margin-top: -10px;
        padding-top: 20px;
        
    }
    
    .form_content{
        display:flex;
        /* padding:5%; */
        gap:5%;
        width:90%;
        margin-top: -60px;
    }
    .a_form_div {
        padding: 5%;
        border-radius: 5%;
    }
    .admission-form-container {
        
        padding: 0rem;
        height: auto;
        margin-top: 8%;
        margin-bottom: 0%;
        
        background-size: cover;
        /* background-position: -330px; */
        
    }
    
}

/* For mobile devices */
@media (max-width: 768px) {
    .admission_h1{
        /* color: #9B5DE5; */
        font-family: "Montserrat", sans-serif;
        font-size: 2.5rem;
        margin-left: 5%;
        margin-top: -5px;
        padding-top: 20px;
        
    }
    
    .form_content{
        display:flex;
        /* padding:5%; */
        gap:5%;
        width:90%;
        margin-top: -60px;
        
    }
    .a_form_div {
        padding: 5%;
        border-radius: 5%;
    }
    .admission-form-container {
        padding: 0rem;
        height: auto;
        margin-bottom: 0;
        margin-top: 10%;
        background-size: cover;
        /* background-position: -330px; */
        
    }
    
}
    

/* For smaller mobile devices */
@media (max-width: 480px) {
    /* .admission_h1 {
        font-size: 35px;
        margin-left: 5%;
    }


    .label {
        font-size: 0.9rem;
    }

    .submit-btn {
        font-size: 0.9rem;
        padding: 0.5rem;
    } */
   
    .checkbox {
        /* margin-right: 100%; */
        height: 15px;
        width: 15px;
     }
     
    .div1_form{
        width: 100%;
    }
    .div2_form{
        width: 100%;
    }
    .input,
    .textarea {
        font-size: 0.9rem;
        
    }
    .admission_h1{
        /* color: black; */
        font-family: "Montserrat", sans-serif;
        font-size: 2rem;
        margin-left: -1px;
        /* margin-left: 5%; */
        margin-top: -10px;
        padding-top: 20px;
        text-align: center;
        
    }
    
    .form_content{
        display:block;
        /* padding:5%; */
        /* gap:5%;
        width:90%; */
        border-radius: 10px;
        margin-top: -30px;
    }
    
    .admission-form-container {
        
        padding-bottom: 2rem;
        height: auto;
        margin-top: 25%;
        margin-bottom: 0;
        
        background-size: cover;
        /* background-position: -330px; */
        
    }
    .submit-btn {
        background-color: #a841c0;
        color: white;
        border: none;
        padding: 0.5rem 0.5rem;
        font-size: 1rem;
        border-radius: 4px;
        cursor: pointer;
        align-self: flex-start;
        font-family: "Montserrat", sans-serif;
        margin-top: 10px;
        justify-content: right;
        margin-left: 70%;
        font-size: 12px;
    }
}

.About_third_main_div{
    height: auto;
    width: 100%;
    /* border: 2px solid; */
display: flex;
background-color: white;
margin-top: 2%;
margin-bottom: -50px;
}
.about_third_div1{
    height: auto;
    width: 78%;
    /* border: 2px solid blue; */
    padding-left: 8%;
    /* padding-top: 7%; */
}
.about_third_div2{
    height: auto;
    width: 100%;
    /* border: 2px solid blue; */
    /* padding-top: 7%; */
}
.about_third_heading{
    font-family: "Montserrat", sans-serif;
    font-size: 60px;
    margin-top: 80px;
    color: #970d99f0;
}
.about_third_para{
height: auto;
width: 70%;
/* border: 2px solid; */
font-size: 18px;
line-height: 27px;
padding-top: 20px;
color: #207e88;
font-family: "Montserrat", sans-serif;
}
.about_third_heading2{
    color:#970d99f0;
font-size: 18px;
font-weight: bold;
/* padding-top: 5%; */
font-family: "Montserrat", sans-serif;
}
.about_third_heading22{
    font-size: 18px;
font-weight: bold;
padding-top: 5%;
color:#970d99f0;
font-family: "Montserrat", sans-serif;

}
.about_third_paragraph{
    height: 10vh;
    width: 70%;
    color:#207e88;
    /* border: 2px solid; */
    font-size: 15px;
    line-height: 25px;
    font-family: "Montserrat", sans-serif;
}
.about_third_hr{
    width: 85%;
margin-left: -1.5%;
}

@media (max-width: 1200px) {
    .about_third_div1 {
        width: 70%;
        padding-left: 5%;
    }

    .about_third_para, .about_third_paragraph {
        width: 75%;
        font-size: 15px; 
    }

    .about_third_heading {
        font-size: 45px; 
    }

    .about_third_heading2 {
        font-size: 16px; 
    }

    .about_third_hr {
        width: 90%; 
        margin-left: 0;
    }
}
@media (max-width: 780px) {
    .about_third_div1 {
        width: 60%;
        padding:2% ;
        /* padding-left: 5%; */
        /* border: solid 2px; */
    }

    .about_third_div2 {
        width: 80%;
        /* padding-top: 5%; */
        /* border: solid 2px; */
    }

    .about_third_para, .about_third_paragraph {
        width: 100%;
        font-size: 14px;
        padding: px;
        /* border: solid 2px; */
        text-align: center;
    }

    .about_third_heading {
        font-size: 40px; 
        /* border: solid 2px; */
        margin-top:40px;
        text-align: center;
    }

    .about_third_heading2 {
        font-size: 15px; 
        text-align: center;
        /* border: solid 2px; */

    }
    .about_third_heading22 {
        font-size: 15px; 
        text-align: center;
        /* border: solid 2px; */
       
    }
    .about_third_hr {
        /* width: 95%;  */
        /* border: solid 2px; */
        /* padding: 10px; */
        margin-top: 50px;
    }
}

@media (max-width: 450px) {
    .about_third_div1 {
        width: 100%;
        padding-left: 2%;
    }

    .about_third_div2 {
        width: 100%;
        padding-top: 2%;
    }
    .About_third_main_div{
        margin-bottom: -30%;
        height: 100%;
        width: 100%;
        /* border: solid 2px; */
        padding: 2%;
        /* border: 2px solid; */
    display: block;
    background-color: white;
    margin-top: 2%;
    }
    .about_third_para, .about_third_paragraph {
        width: 95%;
        font-size: 15px;
        padding: 10px; 
    }

    .about_third_heading {
        font-size: 30px; 
    }

    .about_third_heading2 {
        font-size: 14px; 
    }

    .about_third_hr {
        width: 100%; 
    }
}
/* * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  
  .dhyey_header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
    background-color:white;
    color: black;
  }
  .logo_name{
    font-size: 20px;
    margin-left: -340px;
    margin-top: -15px;
    font-weight: 1000;

    color: #2eb2c1;
  }
  .logo_name h1{
        font-family: "Montserrat", sans-serif;

  }
  .dhyey_logo{
    height: 60px;
    width: 70px;
  }
  
  .nav-links {
    display: flex;
    gap: 20px;
    font-family: "Montserrat", sans-serif;

  }
  
  .nav-links a {
    color: black;
    font-weight: 00;
    text-decoration: none;
    font-size: 18px;
    transition: color 0.3s ease-in-out;
    font-family: "Montserrat", sans-serif;

  }
  
  .nav-links a:hover {
    color: #9800f0;
  }
  
  .mobile-menu-icon {
    display: none;
    font-size: 28px;
    background: none;
    border: none;
    color: white;
    cursor: pointer;
  }
  
  .nav-links-mobile {
    display: flex;
    flex-direction: column;
    gap: 20px;
    position: absolute;
    top: 80px;
    left: 0;
    width: 100%;
    background-color: #333;
    padding: 20px;
  }
  
  @media (max-width: 768px) {
    .nav-links {
      display: none;
    }
  
    .mobile-menu-icon {
      display: block;
    }
  }
   */




   /* * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.dhyey_header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
    background-color: white;
    color: black;
  
}

.logo_name {
    font-size: 20px;
    margin-left: -340px;
    margin-top: -15px;
    font-weight: 1000;
    color: #2eb2c1;
}

.logo_name h1 {
    font-family: "Montserrat", sans-serif;
}

.dhyey_logo {
    height: 60px;
    width: 70px;
}

.nav-links {
    display: flex;
    gap: 20px;
    font-family: "Montserrat", sans-serif;
}

.nav-links a {
    color: black;
    font-weight: 00;
    text-decoration: none;
    font-size: 18px;
    transition: color 0.3s ease-in-out;
    font-family: "Montserrat", sans-serif;
}

.nav-links a:hover {
    color: #9800f0;
}

.mobile-menu-icon {
    display: none;
    font-size: 28px;
    background: none;
    border: none;
    color: black;
    cursor: pointer;
}

.nav-links-mobile {
    display: flex;
    flex-direction: column;
    gap: 20px;
    position: absolute;
    top: 80px;
    left: 0;
    width: 100%;
    background-color: #333;
    padding: 20px;
}

.insight-menu {
    position: relative;
}

.insight-link {
    cursor: pointer;
    font-family: "Montserrat", sans-serif;
    color: black;
    font-size: 18px;

}

.insight-submenu {
    display: flex;
    flex-direction: column;
    gap: 10px;
    position: absolute;
    top: 100%;
    left: 0;
    background-color: white;
    padding: 10px;
    border: 1px solid #ccc;
    width: 200px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    font-family: "Montserrat", sans-serif;
}

.insight-submenu a {
    color: black;
    text-decoration: none;
}

.insight-submenu a:hover {
    color: #9800f0;
}

@media (max-width: 768px) {
    .nav-links {
        display: none;
    }

    .mobile-menu-icon {
        display: block;
    }
} */



* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.dhyey_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 30px;
  background-color: white;
  color: black;
  border-bottom: 2px solid #e0e0e0;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); 
}

.logo_name {
  display: flex;
  align-items: center;
  font-size: 20px;
  font-weight: bold;
  color: #2eb2c1;
}

.logo_name h1 {
  font-family: "Montserrat", sans-serif;
  margin-left: -160px;
}

.dhyey_logo {
  height: 60px;
  width: auto;
}

.nav-links {
  display: flex;
  gap: 20px;
  font-family: "Montserrat", sans-serif;
}

.nav-links a {
  color: black;
  font-weight: 500;
  text-decoration: none;
  font-size: 18px;
  font-family: "Montserrat", sans-serif;
  transition: color 0.3s ease-in-out;
}

.headerlink1:hover {
  background-color: #2eb2c13c;
}
.headerlink2:hover {
  background-color:#2eb2c13c;
}
.headerlink3:hover {
  background-color:#2eb2c13c;
}
.headerlink4:hover {
  background-color: #2eb2c13c;
}
.headerlink5:hover {
  background-color: #2eb2c13c;
}
.headerlink6:hover {
  background-color: #2eb2c13c;
}
.headerlink7:hover {
  background-color: #2eb2c13c;
 }
.headerlink8:hover {
  background-color: #2eb2c13c;
}

.hmobile-menu-icon {
  display: none!important;
  font-size: 28px;
  background: none;
  border: none;
  color: black;
  cursor: pointer;
  z-index: 1000; 
}

.nav-links-mobile {
  display: flex;
  flex-direction: column;
  gap: 15px;
  position: absolute;
  top: 70px; 
  right: 0;
  width: 250px;
  background-color: white;
  padding: 20px;
  border: 1px solid #ddd;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  font-family: "Montserrat", sans-serif;
}

.icons_header{
  display: none;
}
.insight-menu {
  position: relative;
  padding: 10px;
}
.insight-menu:hover{
  background-color: #2eb2c13c;
}
.headerlink1
{
  padding: 10px;
}
.headerlink2
{
  padding: 10px;
}
.headerlink3
{
  padding: 10px;
}
.headerlink4
{
  padding: 10px;
}
.headerlink5
{
  padding: 10px;
}
.headerlink6
{
  padding: 10px;
}
.headerlink7
{
  padding: 10px;
}
.headerlink8
{
  padding: 10px;
}

.insight-link {
  cursor: pointer;
  font-family: "Montserrat", sans-serif;
  color: #970d99f0;
  font-size: 18px;
  font-weight: 500;
}

.insight-submenu {
  display: flex;
  flex-direction: column;
  gap: 10px;
  position: absolute;
  top: 100%;
  left: 0;
  background-color: white;
  padding: 10px;
  border: 1px solid #ccc;
  width: 200px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  font-family: "Montserrat", sans-serif;
}

.insight-submenu a {
  color: black;
  text-decoration: none;
}

.insight-submenu a:hover {
  background-color: #2eb2c13c;
}

@media (max-width: 1024px) {
  .dhyey_header {
    padding: 15px 20px;
    flex-wrap: wrap;
  }

  .logo_name {
    font-size: 18px;
    margin-left: -15px;
    justify-content: center;
  }

  .nav-links {
    display: none;
  }

  .mobile-menu-icon {
    display: block;
  }

  .nav-links-mobile {
    display: flex;
    flex-direction: column;
    gap: 15px;
    position: absolute;
    top: 70px;
    right: 0;
    width: 250px;
    background-color: white;
    padding: 20px;
    border: 2px solid #ddd;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    color:black;
    font-size: 18px;
    font-weight: 500;
    font-family: "Montserrat", sans-serif;
  }
  .headerlink1{
    font-family: "Montserrat", sans-serif;
    color: RED;
  }
  .icons_header{
    display: block;
    
  }
  .nav-links a,
.nav-links-mobile a {
  text-decoration: none;
}
}

@media (max-width: 768px) {
  .nav-links {
      display: none;
  }

  .mobile-menu-icon {
      display: block;
  }

  .nav-links-mobile {
      top: 60px; 
      right: 0;
      font-size: 18px;
      font-weight: 500;
  }
}

@media (max-width: 480px) {
  .logo_name {
      font-size: 20px !important;
  }

  .dhyey_logo {
      height: 50px;
  }

  .nav-links-mobile {
      top: 50px; 
      width: 160px;
      font-size: 15px;
      font-weight: 500;
      margin-top: 35px;
      gap: 10px;
  }

  .nav-links a {
      font-size: 16px; 
  }
  .insight-link{
    font-size: 15px;
  }
}

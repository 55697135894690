/* Global Reset */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* Body */
body {
  font-family: "Montserrat", sans-serif;
}

/* Icon Style */
.icon_service {
  height: 100px;
  width: 100px;
}

/* Services Section */
.services-section {
  text-align: center;
  padding: 40px 20px;
  background-color: #09e8e81d;
  margin-top: 5%;
}

.services-section h1 {
  font-size: 3rem; /* Adjusted for better responsiveness */
  /* color: #970d99; */
  color: #970d99f0;
  font-weight: 700;
  font-family: "Montserrat", sans-serif;
}

.Service-para {
  font-size: 1.125rem; /* Adjusted for better responsiveness */
  color: #565454;
  margin-bottom: 40px;
  font-family: "Montserrat", sans-serif;
  width: 80%;
  margin-left: 10%;
}

/* Services Container */
.services-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center; /* Center items */
  margin-top: 50px;
  width: 100%; /* Full width */
}

/* Service Card */
.service-card {
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  padding: 20px;
  text-align: center;
  width: calc(33.333% - 20px); /* 3 items per row with spacing */
  height: 360px;
  margin: 10px;
  transition: transform 0.3s ease;
}

.service-card:hover {
  transform: translateY(-10px);
}

.service-card h2 {
  font-size: 1.5rem;
  margin-bottom: 15px;
  color: #970d99f0;
  margin-top: 40px;
  font-family: "Montserrat", sans-serif;
}

.service-card p {
  font-size: 1rem; /* Adjusted for better readability */
  color: #666;
  margin-bottom: 20px;
  font-family: "Montserrat", sans-serif;
}

.know-more-btn {
  padding: 10px 20px;
  background-color: transparent;
  border: 2px solid #07dbdb;
  color: #07dbdb;
  border-radius: 5px;
  cursor: pointer;
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  transition: background-color 0.3s ease;
}

.know-more-btn:hover {
  background-color: #07dbdb;
  color: white;
}

/* Media Queries for Responsive Design */
@media (max-width: 1024px) {
  .service-card {
    width: calc(50% - 20px); /* 2 items per row */
  }

  .services-section h1 {
    font-size: 2.5rem; /* Adjusted for medium screens */
  }

  .Service-para {
    font-size: 1rem; /* Adjusted for medium screens */
  }
}

@media (max-width: 768px) {
  .service-card {
    width: calc(100% - 20px); /* 1 item per row */
  }

  .services-section h1 {
    font-size: 2rem; /* Adjusted for small screens */
  }

  .Service-para {
    margin-top: 5%;
    font-size: 0.875rem; /* Adjusted for small screens */
  }

  .services-section {
    padding: 20px 10px; /* Adjusted padding for small screens */
    margin-top: 10%
  }
}
@media (max-width: 480px) {
  .services-section {
    padding: 20px 10px; /* Adjusted padding for small screens */
    margin-top: 20%
  }
}
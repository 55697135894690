/* Container styling */
.testimonials-container {    
  background-color: white;
  padding: 30px;
  padding-bottom: 40px;
  color: white;
  font-family: 'Arial', sans-serif;
  width: 100%;
  box-sizing: border-box;
}

/* Title styling */
.testimonials-title {
  font-size: 2.5rem;
  color: #970d99f0;
  text-align: center;
  padding: 20px;
  font-weight: bold;
  font-family: 'Helvetica', sans-serif;
}

/* Grid layout for testimonials */
.testimonials-grid {
  display: grid;
  grid-template-columns: 1fr 1fr; /* 2 columns */
  gap: 20px;
  width: 100%;
  max-width: 1200px;
  margin: auto;
}

/* Individual testimonial styling */
.testimonial-item {
  display: flex;
  align-items: center; /* Center items vertically */
  gap: 20px;
  color: #2eb2c1;
}

.testimonial-image {
  width: 100%; /* Responsive width */
  max-width: 250px; /* Max width for larger screens */
  height: 300px; /* Maintain aspect ratio */
  aspect-ratio: 1 / 1; /* Square aspect ratio */
  /* object-fit: cover; Ensure the image covers the square area */
}

.testimonial-text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: 1rem;
  text-align: left;
}

h3 {
  font-size: 1.25rem;
  margin: 0;
  font-family: 'Arial', sans-serif;
}

.role {
  font-style: italic;
  color: #207e88;
  margin: 5px 0;
}

.testimonial-quote {
  font-size: 13px;
  line-height: 1.6;
  margin-top: 10px;
  color: #207e88;
}

@media (max-width: 1024px) {
  .testimonial-image {
    width: 250px; /* Adjusted size for smaller screens */
    height: 250px;
    /* object-fit: cover;  */
  }
  .testimonial-text {
    text-align: left;
    font-size: 0.875rem;
  }

  .testimonial-quote {
    font-size: 0.875rem;
    text-align: justify;
  }
}
/* Responsive Design */
@media (max-width: 768px) {
  .testimonials-title {
    font-size: 1.9rem;
    margin-top: -20px;
  }

  .testimonials-grid {
    grid-template-columns: 1fr; /* 1 column on small screens */
  }

  .testimonial-item {
    flex-direction: row; /* Stack image and text vertically */
    align-items: center; /* Center items horizontally */
  }

  .testimonial-image {
    width: 200px; /* Adjusted size for smaller screens */
    height: 200px;
    /* object-fit: cover;  */
  }

  .testimonial-text {
    text-align: left;
    font-size: 0.875rem;
    margin-bottom: 40px;
  }

  .testimonial-quote {
    font-size: 0.875rem;
    text-align: justify;
  }
}

@media (max-width: 480px) {
  .testimonials-title {
    font-size: 30px;
  }

  .testimonials-grid {
    grid-template-columns: 1fr; /* 1 column on small screens */
  }

  .testimonial-item {
    flex-direction: column; /* Stack image and text vertically */
    align-items: center; /* Center items horizontally */
  }

  .testimonial-image {
    width: 100%; /* Responsive width */
    max-width: 250px; /* Max width for larger screens */
    height: 300px; /* Maintain aspect ratio */
    aspect-ratio: 1 / 1; /* Square aspect ratio */
    /* object-fit: cover; Ensure the image covers the square area */
  }

  .testimonial-text {
    text-align: center;
    font-size: 0.875rem;
  }

  .testimonial-quote {
    font-size: 0.875rem;
    text-align: justify;
  }
}

.heart-container {
    position: absolute;
    top: 10px;
    left: 90%;
    transform: translateX(-50%);

}

.heart {
    width: 7vw;
    height: auto;
    position: absolute;
    top: 0;
    animation: none;
}

.heart.animate {
    animation: popupHeart 2s ease-in-out forwards;
}

@keyframes popupHeart {
    0% {
        transform: scale(1);
        opacity: 1;
    }
    50% {
        transform: scale(2);
        opacity: 1;
    }
    100% {
        transform: scale(1);
        opacity: 1;
    }
}

@media (max-width: 480px) {
    .heart {
        margin-top: -100px;
    }
}
.C_card-list{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    /* background-color:rgb(196, 249, 253); */
    /* padding: 50px; */
    /* border: 2px solid blue; */
    width: 100%;
    /* margin: auto; */
    font-family: "Schoolbell";
    font-weight:bold;
    font-style: normal;
    font-size: 20px;
    
    background-size: cover;
}
.C_card_container_div{
    height:fit-content;
    /* border: 2px solid rgb(168, 102, 67); */
    width: 100%;
    /* background-color: rgb(172, 208, 244); */
    /* margin-bottom: -10rem; */
    padding-top: 2rem;
    padding-bottom: 2rem;
    /* background-color: #07dbdb31; */
}
.C_main_div{
    width: 90%;
    /* background-image: url('./Images/bg1\ \(2\).png'); */
    /* background-size: cover; */
    /* background-color: rgba(0, 0, 0, 0.508); */
/* background: rgb(239, 144, 222); */
    
    margin: auto;
    /* margin-top: 5rem;
    margin-bottom: 4rem; */
    border-radius: 10px;
}
.C_Heading_div{
    /* border: 2px solid rgb(79, 79, 83); */
    width: 100%;
    height:20vh;
    /* height: 20vh; */
    margin-top: -38px;
    /* margin: auto; */
    /* background-color: rgb(234, 160, 214); */
    /* padding: 40px; */
    /* background-image: url('./Images/bg_heading.png'); */
    background-size: cover;
}
.C_heading{
    font-size: 60px;
    font-family: "Montserrat", sans-serif;
    font-weight:bold;
    font-style: normal;
    /* font-size: 50px; */
    text-align: center;
    padding: 30px;
    color: #970d99f0;
    
    /* margin-top: 60px; */
}
/* .B_card_div{
    background-image: url('./Images/bg_content.png');
    background-size: cover;
} */
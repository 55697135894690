
  


@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
    
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: "Montserrat", sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
@font-face {
  font-family: 'chawp.otf';
  src: local('chawp.otf'), url('./fonts/chawp.otf') format('opentype');
}
@font-face {
  font-family: 'Rudiment.ttf';
  src: local('Rudiment.ttf'), url('./fonts/Rudiment/Rudiment.ttf') format('truetype');
}
@font-face {
  font-family: 'Adia_Demo.ttf';
  src: local('Adia_Demo.ttf'), url('./fonts/Adia_Demo.ttf') format('truetype');
}
@font-face {
  font-family: 'Comic Helvetic.otf';
  src: local('Comic Helvetic.otf'), url('./fonts/ComicHelvetic_Medium.otf') format('opentype');
}
.fee-structure-page {
    max-width: 80%;
    margin: 0 auto;
    padding: 30px;
    text-align: center;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }

  .feestructure-heading{
    text-align: center;
    font-family: "Montserrat", sans-serif;
    font-size: 50px;
    padding: 2rem;
    color: #862d86;
  }
  .fee-structure-page h1 {
    font-size: 2em;
    margin-bottom: 20px;
    color: #333;
    font-family: "Montserrat", sans-serif;
  }
  
  .fee-table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 20px;
  }
  
  .fee-table th,
  .fee-table td {
    border: 2px solid black;
    padding: 10px;
    text-align: center;
    font-family: "Montserrat", sans-serif;
  }
  
  .fee-table th {
    background-color: #D8BFD8;
  }
  
  .download-btn {
    padding: 10px 20px;
    font-size: 1em;
    color: #fff;
    background-color: #862d86;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-family: "Montserrat", sans-serif;
  }
  
  .download-btn:hover {
    background-color: #0056b3;
  }
  .fee_main_div{
    height: 100%;
    margin-top: 5%;
    background-color: #07dbdb31;
  }
  @media (max-width: 768px) {
    .feestructure-heading {
        font-size: 2rem;
    }

    .fee-table th, 
    .fee-table td {
        font-size: 0.9em; /* Reduce font size on smaller screens */
        padding: 8px; /* Reduce padding on smaller screens */
    }

    .download-btn {
        font-size: 0.9em; /* Reduce button font size */
        padding: 8px 16px; /* Reduce button padding */
    }
    .fee_main_div{
      margin-top: 10%;
   
    }
}

@media (max-width: 480px) {
    .feestructure-heading {
        font-size: 1.2rem;
    }

    .fee-table th, 
    .fee-table td {
        font-size: 0.6em; /* Further reduce font size on very small screens */
        padding: 3px; /* Further reduce padding */
    }
    .fee_main_div{
      margin-top: 20%;
    }
    .download-btn {
        font-size: 0.8em; /* Further reduce button font size */
        padding: 6px 12px; /* Further reduce button padding */
    }
}
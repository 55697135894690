.Online_course_container {
    display: flex;
    justify-content: center;
    padding: 20px;
    background-color: white; /* Light background color */
    flex-wrap: wrap;
    margin-top: 5%;
}

.Online_courses_title {
    font-size: 50px;
    color: #970d99f0;
    margin-bottom: 30px;
    text-align: center;
    width: 100%;
    font-family: "Montserrat", sans-serif;
}

.Online_course_card {
    background-color: #f3f4ff;
    padding: 20px;
    border-radius: 8px;
    width: 400px;
    text-align: center;
    margin: 10px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.Online_course_card1 {
    background-color: white;
    padding: 20px;
    border-radius: 5px;
    width: 350px;
    text-align: center;
    margin: 10px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.Online_course_card:hover,
.Online_course_card1:hover {
    transform: translateY(-10px);
    box-shadow: 0 10px 15px rgba(0, 0, 0, 0.2);
}

.Online-title {
    font-size: 1.5em;
    font-weight: bold;
    margin-bottom: 20px;
    color: #2eb2c1;
    font-family: "Montserrat", sans-serif;
    text-align: center;
}

.Online-items li {
    font-family: "Montserrat", sans-serif;
    margin-bottom: 5px;
    color: #970d99f0;
}

/* .download-pdf-button-course {
    margin-top: 15px;
    font-weight: bold;
    background-color: #4CAF50; 
    color: white; 
    border: none; 
    transition: background-color 0.3s ease, transform 0.3s ease;
}

.download-pdf-button-course:hover {
    background-color: #45a049;
    transform: translateY(-3px); 
} */


.download-pdf-button-course {
    width: 125px;
    height: 45px;
    border-radius: 20px;
    border: none;
    box-shadow: 1px 1px rgba(107, 221, 215, 0.37);
    padding: 5px 10px;
    background-color: rgb(59, 190, 230);
    color: #fff;
    font-family: Roboto, sans-serif;
    font-weight: 505;
    font-size: 16px;
    line-height: 1;
    cursor: pointer;
    filter: drop-shadow(0 0 10px rgba(59, 190, 230, 0.568));
    transition: 0.5s linear;
  }
  
  .download-pdf-button-course .mysvg {
    display: none;
  }
  
  .download-pdf-button-course:hover {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    transition: 0.5s linear;
  }
  
  .download-pdf-button-course:hover .texto {
    display: none;
  }
  
  .download-pdf-button-course:hover .mysvg {
    display: inline;
  }
  
  .download-pdf-button-course:hover::after {
    content: "";
    position: absolute;
    width: 16px;
    height: 3px;
    background-color: rgb(59, 190, 230);
    margin-left: -20px;
    animation: animate 0.9s linear infinite;
  }
  
  .download-pdf-button-course:hover::before {
    content: "";
    position: absolute;
    top: -3px;
    left: -3px;
    width: 100%;
    height: 100%;
    border: 3.5px solid transparent;
    border-top: 3.5px solid #fff;
    border-right: 3.5px solid #fff;
    border-radius: 50%;
    animation: animateC 2s linear infinite;
  }
  
  @keyframes animateC {
    0% {
      transform: rotate(0deg);
    }
  
    100% {
      transform: rotate(360deg);
    }
  }
  
  @keyframes animate {
    0% {
      transform: translateY(0);
    }
  
    100% {
      transform: translateY(20px);
    }
  }
  @media (max-width: 768px) {
    .Online_courses_title {
      font-size: 2.5rem;
    }
    .Online_course_container{
      margin-top: 10%;
    }
  }
  @media (max-width: 480px) {
    .Online_courses_title {
      font-size: 2rem;
    }
    .Online_course_container{
      margin-top: 22%;
    }
  }
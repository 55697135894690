.news-events-page {
    padding: 20px;
    max-width: 1200px;
    margin: 0 auto;
    margin-bottom: 0px;
}

.news-events-mainpage {
    background-color: #09e8e81d;
    margin-top: 5%;
}

.news-section h2 {
    font-family: "Montserrat", sans-serif;
    font-size: 40px;
    color: #970d99;
}

.news-events-title {
    text-align: center;
    font-size: 60px;
    margin-bottom: 20px;
    color: #970d99;
    font-family: "Montserrat", sans-serif;

}

.news-section {
    margin-bottom: 40px;
}

.news-cards {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
    gap: 20px;
}

.news-card {
    background-color: #f9f9f9;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.203);
    transition: transform 0.3s ease-in-out;
}

.news-card:hover {
    transform: scale(1.05);
}

.news-image {
    width: 100%;
    height: 200px;
    object-fit: cover;
}

.news-content {
    padding: 15px;
    font-family: "Montserrat", sans-serif;

}

.news-content h3 {
    margin-top: 0;
    font-size: 20px;
    color: #2eb2c1;
    font-family: "Montserrat", sans-serif;

}

.news-date {
    color: #282828;
    font-size: 14px;
    margin-bottom: 10px;
}

/* Events Section */
.events-section {
    margin-bottom: 40px;
}

.events-section h2 {
    font-family: "Montserrat", sans-serif;
    font-size: 40px;
    color: #970d99;
}

.events-list {
    list-style: none;
    padding: 0;
}

.event-item {
    background-color: #ffffff;
    padding: 20px;
    margin-bottom: 15px;
    border-radius: 8px;
    font-family: "Montserrat", sans-serif;

}

.event-item h3 {
    margin-top: 0;
    font-size: 20px;
    color: #1c1c1c;
    font-family: "Montserrat", sans-serif;
    color: #2eb2c1
}

.event-date,
.event-location {
    font-size: 14px;
    color: #353434;
    font-family: "Montserrat", sans-serif;

}

/* Responsive Design */
@media (max-width: 768px) {
    .news-events-title {
        font-size: 2.5rem;
    }

    .news-content h3 {
        font-size: 18px;
    }

    .event-item h3 {
        font-size: 18px;
    }

    .news-events-mainpage {
        margin-top: 10%;
    }

    .news-section h2,
    .events-section h2 {
        font-size: 2rem;
    }
}

@media (max-width: 480px) {
    .news-events-mainpage {
        margin-top: 20%;
    }

    .news-events-title {
        font-size: 2rem;
    }

    .news-section h2,
    .events-section h2 {
        font-size: 1.5rem;
    }
}
/* Global Styles */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.footer_container {
  width: 100%;
  background-color:  #055761;
  padding: 20px;
  text-align: center;
  color: white;
}

.footer_section {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
}

.footer_paragraph {
  color: white;
  margin-right: 10px; 
  font-size: 1rem; /* Adjusted for better responsiveness */
  font-weight: bold;
}

.footer_hr {
  width: 80%;
  height: 1px;
  border: none;
  background-color: rgb(168, 164, 164);
}

.footer_hr1 {
  width: 80%;
  height: 1px;
  border: none;
  background-color: rgb(168, 164, 164);
  /* margin-left: 8.4%; */
}

.social_icons {
  display: flex;
  justify-content: center;
  gap: 20px; /* Added gap for spacing */
  color: white;
  font-size: 1.5rem; /* Adjusted size for better visibility */
}

.footer_section2 {
  display: flex;
  flex-wrap: wrap; /* Allow wrapping on smaller screens */
  gap: 5%; /* Adjust gap between sections */
  text-align: center; /* Center align text for smaller screens */
  margin-top: 0; /* Removed negative margin */
}

.footer_div1, .footer_div2 {
  text-align: left;
  width: 100%; /* Full width on smaller screens */
  padding: 0 10%; /* Adjusted padding for better spacing */
  margin-bottom: 20px; /* Added margin for spacing */
}

.footer_div1_para {
  font-size: 1.125rem; /* Adjusted for responsiveness */
  color: rgb(199, 194, 194);
}

.footer_div1_paragraph {
  font-size: 0.875rem; /* Adjusted for responsiveness */
  color: rgb(219, 215, 215);
}

.footer_last_main_div, .footer_last_main_div1 {
  display: flex;
  flex-wrap: wrap; /* Allow wrapping on smaller screens */
  justify-content: center; /* Center align content */
  gap: 20px; /* Added gap between items */
  margin-top: 20px; /* Added margin for spacing */
  font-size: 0.875rem; /* Adjusted for responsiveness */
  text-align: center; /* Center align text for smaller screens */
}

.footer_last_main_div p, .footer_last_main_div1 p {
  margin: 5px 0; /* Added margin for spacing */
}

.footer_a {
  color: rgb(36, 241, 173);
  font-size: 1rem; /* Adjusted for responsiveness */
  font-weight: bold;
  text-decoration: none; /* Removed underline */
}

/* Media Queries for Responsive Design */
@media (max-width: 1024px) {
  .footer_paragraph {
    font-size: 0.875rem; /* Adjusted for medium screens */
  }

  .social_icons {
    font-size: 1.25rem; /* Adjusted size for medium screens */
  }

  .footer_div1, .footer_div2 {
    width: 100%; /* Full width on medium screens */
    padding: 0 5%; /* Adjusted padding for medium screens */
  }

  .footer_last_main_div, .footer_last_main_div1 {
    gap: 15px; /* Adjusted gap for medium screens */
  }
}

@media (max-width: 768px) {
  .footer_paragraph {
    font-size: 0.75rem; /* Adjusted for small screens */
  }

  .social_icons {
    font-size: 1rem; /* Adjusted size for small screens */
  }

  .footer_div1, .footer_div2 {
    padding: 0 2%; /* Adjusted padding for small screens */
  }

  .footer_last_main_div, .footer_last_main_div1 {
    font-size: 0.75rem; /* Adjusted for small screens */
    gap: 10px; /* Adjusted gap for small screens */
  }
  #SUBSCRIBE_BUTTON{
    margin-top: 10px;
  }
}

@media (max-width: 480px) {
  .footer_paragraph {
    font-size: 0.625rem; /* Adjusted for very small screens */
  }

  .social_icons {
    font-size: 0.875rem; /* Adjusted size for very small screens */
  }

  .footer_last_main_div, .footer_last_main_div1 {
    font-size: 0.625rem; /* Adjusted for very small screens */
  }

  .footer_div1, .footer_div2 {
    padding: 0 1%; /* Adjusted padding for very small screens */
  }
}

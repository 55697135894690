@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100..900&display=swap');

.dcontact-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 30px 0;
  width: 100%;
  background-color: #09e8e81d;
  margin-bottom: -11rem;
}

.dcontent_container {
  width: 100%;
  margin-top: 5%;
}

.dcontact-wrapper {
  display: flex;
  flex-wrap: wrap;
  padding: 0;
  max-width: 1200px;
  width: 100%;
  border-radius: 10px;
  overflow: hidden;
  margin: auto;
  height: auto; /* Adjusted for flexibility */
  background-color: #e0dedc;
}

.dcontact-us,
.dcontact-form {
  padding: 20px;
  box-sizing: border-box;
}

.dcontact-us {
  width: 100%;
  max-width: 450px;
  flex: 1;
  margin-right: 20px;
}

/* .dcontact-head {
  margin-top: 30px;
  font-family: "Montserrat", sans-serif;
} */

.dcontact_h1 {
  padding: 20px;
  font-size: 40px;
  color: #9B5DE5;
  font-family: "Montserrat", sans-serif;

}
.dcontact_p{
  font-family: "Montserrat", sans-serif;
  padding: 20px;
  color: #196fcc;
  margin-top: -20px;
}

.dcontact-info {
  margin-bottom: 30px;
  width: 100%;
  padding: 20px;
  font-family: "Montserrat", sans-serif;

}

.dcontact-info h2 {
  font-size: 2rem;
  color: #9B5DE5;
  padding-bottom: 20px;
  font-family: "Montserrat", sans-serif;
  /* padding: 20px; */
}

.dcontact-info p {
  color: #196fcc;
  font-size: 1rem;
  font-family: "Montserrat", sans-serif;
  padding-bottom: 10px;

}

.dcontact-info svg {
  margin-right: 10px;
}

.dcontact-form {
  flex: 2;
  margin-left: 20px;
}

.dcontact-form h1 {
  font-size: 2rem;
  color: #0e1e40;
}
.dcontact-wrapper{
  background-color: white;
}
.dcontact-input-group {
  margin-bottom: 20px;
}

.dcontact-input-group label {
  display: block;
  font-size: 1rem;
  margin-bottom: 5px;
  color: #9B5DE5;
  font-weight: bold;
  font-family: "Montserrat", sans-serif;

}

.dcontact-input-group input,
.dcontact-input-group textarea {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 1rem;
  transition: border-color 0.3s;
  font-family: "Montserrat", sans-serif;
  


}
/* ::placeholder{
  color: #007bff;
} */
.dcontact-input-group input:focus,
.dcontact-input-group textarea:focus {
  border-color: #007bff;
}

.dcontact-button {
  width: 100%;
  padding: 10px;
  border: none;
  border-radius: 5px;
  background-color: #9B5DE5;
  color: white;
  font-size: 1rem;
  font-weight: bold;
  cursor: pointer;
  font-family: "Montserrat", sans-serif;

  transition: background-color 0.3s;
}

.dcontact-button:hover {
  background-color:  #0056b3;
}

.dlocation {
  width: 100%;
  text-align: center;
  padding: 20px;
}

.dlocation h1 {
  font-size: 3rem;
  color: #9B5DE5;
  font-family: "Montserrat", sans-serif;

}

.dmap-container {
  margin-top: 20px;
}

.dlocations_maping {
  width: 100%;
  height: 350px;
  border: 0;
}


@media (max-width: 1024px) {
  .dcontact_h1 {
    font-size: 2rem;
  }

  .dcontact-head p {
    font-size: 0.875rem;
  }

  .dcontact-info {
    width: 90%;
  }

  .dcontact-info h2 {
    font-size: 1.25rem;
  }

  .dcontact-info p {
    font-size: 0.875rem;
  }

  .dcontact-form h1 {
    font-size: 1.5rem;
  }
  .dcontent_container{
    margin-top: 7%;
  }
}

@media (max-width: 768px) {
  .dcontact-wrapper {
    flex-direction: column;
    height: auto;
  }
.dcontact_p{
  margin-top: -20px;
}
  .dcontact-us,
  .dcontact-form {
    width: 100%;
    margin: 0;
  }
  .dcontent_container{
    margin-top: 10%;
  }
.dcontacthead{
  width: 100%;
}
  .dcontact-head {
    margin-top: 20px;
  }

  .dcontact-head p {
    font-size: 0.75rem;
  }

  .dcontact-info {
    width: 100%;
  }

  .dcontact-info h2 {
    font-size: 1rem;
  }

  .dcontact-info p {
    font-size: 0.75rem;
  }

  .dcontact-form h1 {
    font-size: 1.25rem;
  }

  .dcontact-input-group label,
  .dcontact-input-group input,
  .dcontact-input-group textarea {
    font-size: 0.875rem;
  }

  .dcontact-button {
    font-size: 0.875rem;
  }

  .dlocations_maping {
    height: 200px;
  }
  .dlocation h1{
font-size: 2.2rem;
  }
}

@media (max-width: 482px) {
  .dcontact_h1 {
    font-size: 1.5rem;
  }
.dcontent_container{
  margin-top:18%;
}
  .dcontact-head p {
    font-size: 0.625rem;
  }
  .dcontacthead{
  width: 100%;
}
  .dcontact-info {
    width: 100%;
    margin: 0;
  }

  .dcontact-info h2 {
    font-size: 0.875rem;
  }

  .dcontact-info p {
    font-size: 0.625rem;
  }

  .dcontact-form h1 {
    font-size: 1rem;
  }

  .dcontact-input-group label,
  .dcontact-input-group input,
  .dcontact-input-group textarea {
    font-size: 0.75rem;
  }

  .dcontact-button {
    font-size: 0.75rem;
  }

  .dlocation {
    margin-top: 10px;
  }

  .dlocations_maping {
    height: 150px;
  }
  .dlocation h1{
    font-size: 1.5rem;
      }

      .address-div p{
        font-size: 1rem;
      }
    
}

.workshop_container {
    background-size: cover;
    padding: 40px 20px;
    text-align: center;
    margin-top: 3%;
}

.workshop_title {
    font-size: 60px;
    color: #970d99f0;
    margin-bottom: 30px;
    text-align: center;
    font-family: "Montserrat", sans-serif;

}

.workshop_cards {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 20px;
    justify-content: center;
}

.workshop_card {
    background-color: #f3f4ff; /* Change background to a light purple color */
    border-radius: 12px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.242);
    border-left: 3px solid #970d99f0;
    padding: 20px;
    height: 180px;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    position: relative; /* Added to position the blue label */
    text-decoration: none !important;
}

.workshop_card:hover {
    transform: translateY(-10px);
    box-shadow: 0 10px 15px rgba(0, 0, 0, 0.315);
}

.workshop_header {
    position: absolute;
    top: 0;
    left: 0;
    background-color: #970d99f0;
    color: #fff;
    padding: 5px 15px;
    border-top-left-radius: 12px;
    border-bottom-right-radius: 12px;
}

.workshop_info {
    text-align: center;
    padding-top: 20px;
    text-decoration: none !important;
}

.workshop_name {
    text-align: center;
    font-size: 25px;
    color: #2eb2c1;
    margin-bottom: 10px;
    font-family: "Montserrat", sans-serif;
    padding:10px;
    text-decoration: none !important;
}

.workshop_card p {
    font-size: 16px;
    color: #474747;
    line-height: 1.5;
    font-family: "Montserrat", sans-serif;

}

@media (max-width: 768px) {
    .workshop_cards {
        grid-template-columns: 1fr;
    }
    .workshop_container{
        margin-top: 8%;
    }
    .workshop_title{
        font-size: 2.5rem;
    }
}
  @media (max-width: 480px) {
    .workshop_container{
        margin-top: 15%;
    }
    .workshop_title{
        font-size: 2.2rem;
    }
  }
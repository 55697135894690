*{
    font-family: "Montserrat", sans-serif;
}
/* 1--------------------------------------------------- */
.About_first_main_div{
    height: auto;
    width: 100%;
    /* border: 2px solid; */
    display: flex;
    padding-top: 5%;
    margin-bottom: -160px;
    background-color: white;
}
.about_first_div{
height: 80vh;
width: 60%;
margin-left: 80px;
margin-top: -40px;
/* justify-content:center; */
/* border: 2px solid blue; */
}

.about_firstpage_div_paragraph{
    /* height: 40vh; */
    width: 80%;
    padding-left: 10%;
   font-size: 16px;
    margin-top: 10%;
    font-weight: 600px;
    /* border: 2px solid; */
  line-height: 22px;
  color:#207e88;
  font-family: "Montserrat", sans-serif;
}

.about_heading_h2{
    /* height: 22vh; */
    width: 100%;
    /* padding: 20px; */
    padding-left: 55px;
    color: #970d99f0;
    font-size: 50px;
    font-family: "Montserrat", sans-serif;
    /* border: 2px solid;     */
    /* margin-top: -5px; */
}
.about_first_div2{
    height: auto;
    width: 40%;
    /* border: 2px solid; */
    margin-top: -30px;
}
.about_first_div2_image{
    height: 560px !important;
    width: auto;
    /* padding-left: 15%; */
    /* border: 2px solid; */
}

.button-89 {
  --b: 3px;   /* border thickness */
  --s: .45em; /* size of the corner */
  --color: #970d99f0;
  
  padding: calc(.5em + var(--s)) calc(.9em + var(--s));
  color: var(--color);
  --_p: var(--s);
  background:
    conic-gradient(from 90deg at var(--b) var(--b),#0000 90deg,var(--color) 0)
    var(--_p) var(--_p)/calc(100% - var(--b) - 2*var(--_p)) calc(100% - var(--b) - 2*var(--_p));
  transition: .3s linear, color 0s, background-color 0s;
  outline: var(--b) solid #0000;
  outline-offset: .6em;
  font-size: 16px;
  font-family: "Montserrat", sans-serif;
  border: 0;

  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  margin-left: 70%;
  /* margin-top: 80px; */
}
.Aboutbtn{
    /* border: rgb(133, 46, 46) solid 2px; */
    margin-top: 1%;
}

.button-89:hover,
.button-89:focus-visible{
  --_p: 0px;
  outline-color: var(--color);
  outline-offset: .05em;
}

.button-89:active {
  background: var(--color);
  color: #fff;
}

@media (max-width: 1200px) {
    .about_first_div, .about_first_div2 {
        width: 50%;
        height: fit-content;
    }
    .About_first_main_div{
        height: auto;
        width: 100%;
        /* border: 2px solid; */
        display: flex;
        /* padding-top: 5%; */
        margin-bottom: -200px;
    }

    .about_firstpage_div_paragraph {
        width: 85%;
        font-size: 15px;
        padding-left: 8%;
    }

    .about_heading_h2 {
        font-size: 40px;
        padding-left: 40px;
        margin-top:20px ;
        /* padding-top: 0px; */
        /* border: black solid 2px */
    }

    .about_first_div2_image {
        width: 98%;
        height: 80%;
        padding: 5%;
    }
    .Aboutbtn{
        /* border: black solid 2px; */
        /* margin-top: 100px; */
        padding: 0rem;
        margin-top: 10%;
        justify-content: right;
        margin-bottom: 20px;
        text-align: right;
        margin-right: 3%;
    }
    .button-89{
        margin-top: -25px;
                /* border: black solid 2px; */

    }
   
}

/* Medium Screens (750px to 1199px) */
@media (max-width: 780px) {
    .button-89 {
        margin-left: 50%;
    }
    .about_first_div, .about_first_div2 {
        width: 100%;
        margin: 0;
        
        height: 80vh;
                        /* border: black solid 2px; */

    }
    
    .about_firstpage_div_paragraph {
        width: 90%;
        /* height:40px !important;  */
        font-size: 14px;
        padding-left: 5%;
        margin-top: 15%; /* Reset margin */
    }

    .about_heading_h2 {
        font-size: 40px;
        padding-left: 20px;
        
    }

    .about_first_div2_image {
        /* width: 0%; */
        height:520px !important;
        /* border: rgb(185, 55, 55) solid 2px; */

            /* padding-left: 0; */
    }

}

/* Small Screens (450px to 749px) */
@media (max-width: 480px) {
    .about_first_div, .about_first_div2 {
        width: 100%;
        margin: 0;
        margin-top: 0%;
        height:fit-content;
        justify-content: center;
                        /* border: black solid 2px; */

    }
    .about_first_div{
        margin-top: -20px;
    }
    .About_first_main_div{
        height: auto;
        width: 100%;
        /* border: 2px solid; */
        display: block;
        /* padding-top: 5%; */
    }
    .about_firstpage_div_paragraph {
        width: 95%;
        font-size: 15px;
        /* padding-left: 0%; */
        /* border: 2px solid; */
        height: auto;
        /* padding-bottom: 10px; */
    }

    .about_heading_h2 {
        font-size: 30px;
        
        /* margin-top: -20px; */
        /* padding-left: 10px; */
    }

    .about_first_div2_image {
        /* margin-top: 50px; */
        width: 100%;
        padding-bottom: 40px;
        height: 10% !important;
        /* padding-left: 0; */
        /* margin-top: 80px; */
    }
    
}
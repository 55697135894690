.shortterm_courses_container {
    padding: 20px;
    margin-top: 5%;
}

.courses_title {
    font-size: 3rem;
    text-align: center;
    margin-bottom: 20px;
    color: #9b5de5;
    font-family: "Montserrat", sans-serif;

}

.shortterm_courses_div {
    display: flex;
    justify-content: center;
}

.course_card {
    display: flex;
    height: 200px;
    border: 1px solid #ddd;
    border-radius: 8px;
    background-color: #fff;
}

.course_content_container {
    display: flex;
    align-items: center;
    gap: 20px;
}

.course_image img {
    width: 100px; 
    height: auto;
    border-radius: 8px;
}

.course_details {
    font-family: "Montserrat", sans-serif;

    display: flex;
    flex-direction: column;
    justify-content: center;
}

.course_details h3 {
    margin: 0;
    font-size: 19px;
    font-weight: bold;
    color: #9b5de5;
    font-family: "Montserrat", sans-serif;

}

.course_details p {
    font-size: 12px;
    color: black;
    font-family: "Montserrat", sans-serif;

}

.enroll_button {
    background-color: #1890ff;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.enroll_button:hover {
    background-color: #40a9ff;
}
@media (max-width: 768px) {
    .shortterm_courses_container{
        margin-top: 10%;
    }
    .courses_title{
        font-size: 2.5rem;
       }
    .course_card {
        display: flex;
        height: 280px;
        border: 1px solid #ddd;
        border-radius: 8px;
        background-color: #fff;
    }
}
  @media (max-width: 480px) {
   .shortterm_courses_container{
    margin-top: 20%;
   }
   .courses_title{
    font-size: 2rem;
   }
  }

.home-page {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #e8c0ef1f;
    position: relative;
}
.social_m{
    height: 25px;
    width: 25px;
    border-radius: 5px;
}
.social-container-div{
    position: absolute;
   
    margin-right: 900px;
    margin-top: -550px;
}
.home_logo{
    height: 150px;
    width: 150px;
    /*  */
}
.home_social_div{
    display: flex;
    /* margin: auto; */
    /* text-align: center; */
    gap: 16px;
    /* padding: 5px; */
}
.insta_logo{
    width: 40px;
    color: black;
}
.behance_logo{
    width: 40px;
    color: black;
}
.linkedin_logo{
    color: black;
}
.face_logo{
    width: 8px;
    padding-left: 10px;
    color: black;
}
.lion-container {
    position: relative;
    z-index: 1;
}

.lion {
    width: 400px; /* Increased size */
    height: auto;
    margin-top: 60px;
    /* border: 2px solid rgb(243, 231, 231); */

}

.buttons-container {
    display: flex;
    justify-content: space-between;
    width: 71%;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    /* border: 2px solid rgb(180, 100, 9); */
}

.right-buttons {
    display: flex;
    flex-direction: column;
    margin-top: 70px;
    /* border: 2px solid rgb(9, 180, 23); */
}
.left-buttons {
    display: flex;
    flex-direction: column;
    margin-top: 70px;
    /* border: 2px solid rgb(9, 180, 23); */
}


.left-buttons button:hover,
.right-buttons button:hover {
    background-color: #90D5FF;
    
}

.left-buttons {
    margin-right: -150px; /* Adjust this value to control the overlap */
    align-items: flex-end;

}

.right-buttons {
    margin-left: -150px; /* Adjust this value to control the overlap */
    align-items: flex-start;
}

.Home_Button1{
    padding-left: 50px;
    width: 400px;
    text-align: left;
    /* padding: 10px 20px; */
    border-radius: 30px;
    background-color: #ffdf4178;
    color:  #615208;
    height: 50px;
    width: 350px;
    font-family: 'Adia_Demo.ttf', sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 23px;
    border: none !important;
}
.Home_Button2{
    padding-left: 50px;
    width: 420px;
    text-align: left;
    /* padding: 10px 20px; */
    border-radius: 30px;
    background-color: #88e4eb6f;
    color: #065d63;
    height: 50px;
    margin-top: -20px;
    font-family: 'Adia_Demo.ttf', sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 23px;
    border: none !important;
}
.Home_Button3{
    padding-left: 50px;
    width: 300px;
    text-align: left;
    /* padding: 10px 20px; */
    border-radius: 30px;
    background-color: #ffdf4178;
    color:  #615208;
    height: 50px;
    font-family: 'Adia_Demo.ttf', sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 23px;
    margin-top: 10px;
    border: none !important;
}
.Home_Button4{
    padding-right: 60px;
    width: 240px;
    text-align: right;
    /* padding: 10px 20px; */
    border-radius: 30px;
    background-color: #e86ee031;
    color: #5e0f59 ;
    height: 50px;
    margin-top: 35px;
    font-family: 'Adia_Demo.ttf', sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 23px;
    border: none !important;
}
.Home_Button5{
    padding-right: 60px;
    width: 300px;
    text-align: right;
    margin-right: 80px;
    /* padding: 10px 20px; */
    border-radius: 30px;
    background-color: #ffdf4178;
    color:  #615208;
    height: 50px;
    font-family: 'Adia_Demo.ttf', sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 23px;
    border: none !important;
}
.Home_Button6{
    padding-right: 40px;
    width: 370px;
    text-align: right;
    /* padding: 10px 20px; */
    border-radius: 30px;
    background-color: #88e4eb6f;
    color: #065d63;
    height: 50px;
    margin-left: -80px;
    font-family: 'Adia_Demo.ttf', sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 23px;
    border: none !important;
}
.Home_Button7{
    padding-right: 60px;
    width:380px;
    text-align: right;
    /* padding: 10px 20px; */
    border-radius: 30px;
    background-color: #e86ee031;
    color: #5e0f59 ;
    height: 50px;
    margin-left: -140px;
    font-family: 'Adia_Demo.ttf', sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 23px;
    border: none !important;
}
.Home_Button8{
    padding-left: 70px;
    width: 280px;
    margin-left: 10px;
    text-align: left;
    margin-right: -20px;
    
    border-radius: 30px;
    margin-top: 20px;
    background-color: #88e4eb6f;
    color: #065d63;
    height: 50px;
    font-family: 'Adia_Demo.ttf', sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 23px;
    border: none !important;
}
.Home_Button9{
    padding-left: 50px;
    width: 380px;
    text-align: left;
    /* padding: 10px 20px; */
    border-radius: 30px;
    background-color: #e86ee031;
    color: #5e0f59 ;
    height: 50px;
    /* width: 400px; */
    font-family: 'Adia_Demo.ttf', sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 23px;
    margin-top: -20px;
    margin-bottom: 10px;
    border: none !important;

}
@media (max-width: 1024px) {
    .social-container-div{
        position: absolute;
       
        margin-right: 600px;
        margin-top: -550px;
    }
    .home_logo{
        height: 150px;
        width: 150px;
        /*  */
    }
    .right-buttons {
        display: flex;
        flex-direction: column;
        margin-top: 70px;
        /* border: 2px solid rgb(9, 180, 23); */
    }
    .left-buttons {
        display: flex;
        flex-direction: column;
        margin-top: 70px;
        /* border: 2px solid rgb(9, 106, 180); */
    }
    .buttons-container {
        display: flex;
        justify-content: space-between;
        width: 93%;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        /* border: 2px solid rgb(2, 3, 4); */
    }
}
@media (max-width: 768px) {
    .social-container-div{
        position: absolute;
        
        margin-right: 400px;
        margin-top: -400px;
    }
    .insta_logo{
        width: 40px;
        color: black;
        margin-left: -10px;
    }
    .home_social_div{
        display: flex;
        /* margin: auto; */
        /* text-align: center; */
        gap: 5px;
        
        /* padding: 5px; */
    }
    .home_logo{
        height: 115px;
        width: 115px;
        /*  */
    }
    .lion {
        width: 340px; /* Increased size */
        height: auto;
        margin-top: 60px;
        /* border: 2px solid rgb(243, 231, 231); */
    
    }
    .right-buttons {
        display: flex;
        flex-direction: column;
        margin-top: 70px;
        /* border: 2px solid rgb(9, 180, 23); */
        width: 70%;
    }
    .left-buttons {
        display: flex;
        flex-direction: column;
        margin-top: 70px;
        /* border: 2px solid rgb(9, 106, 180); */
        width: 70%;
    }
    .buttons-container {
        display: flex;
        justify-content: space-between;
        width: 80%;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        /* border: 2px solid rgb(2, 3, 4); */
       
    }
    .Home_Button1{
        height: 45px;
        width: 400px;
        font-size: 20px;
    }
    .Home_Button2{
        height: 45px;
        width: 450px; 
        font-size: 20px;
    }
    .Home_Button3{
        height: 45px;
        width: 300px;
        font-size: 20px;
        margin-right: 100px;
    }
    .Home_Button8{
        height: 45px;
        width: 260px;
        font-size: 20px;
        margin-right: 100px;
    }
    .Home_Button4{
        height: 45px;
        width: 400px;
        margin-left: 20px;
        font-size: 20px;
    }
    .Home_Button5{
        height: 45px;
        width: 460px;
        margin-left: 30px;
        font-size: 20px;
    }
    .Home_Button6{
        height: 45px;
        width: 400px;
        margin-left: 60px;
        font-size: 20px;
    }
    .Home_Button7{
        height: 45px;
        width: 320px;
        margin-left: 100px;
        font-size: 20px;
    }
    .Home_Button9{
        height: 45px;
        width: 450px; 
        font-size: 20px;
        margin-right: -25px;
    }
   
}
    @media (max-width: 480px) {
        .social-container-div{
            position: absolute;
            
            margin-right:150px;
            margin-top: -400px;
        }
        .insta_logo{
            width: 40px;
            color: black;
            margin-left: -10px;
        }
        .home_social_div{
            display: flex;
            /* margin: auto; */
            /* text-align: center; */
            gap: 5px;
            
            /* padding: 5px; */
        }
        .home_logo{
            height: 115px;
            width: 115px;
            /*  */
        }
        .lion {
            width: 180px; /* Increased size */
            height: 230px;
            margin-top: -80px;
    
        }
        .right-buttons {
            display: flex;
            flex-direction: column;
            margin-top: 40px;
            /* border: 2px solid rgb(9, 180, 23); */
            width: 65%;
        }
        .left-buttons {
            display: flex;
            flex-direction: column;
            margin-top: 20px;
            /* border: 2px solid rgb(9, 106, 180); */
            width: 45%;
            /* height:100px ; */
        }
        .buttons-container {
            display: flex;
            justify-content: space-between;
            width: 90%;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            
        }
        .Home_Button1{
            height: 20px;
            width: 120px;
            font-size: 9px;
            margin-top: -90px;
            padding-left: 20px;
        }
        .Home_Button2{
            height: 20px;
            width: 143px; 
            font-size: 9px;
            padding-left: 20px;
            margin-top: -30px;
        }
        .Home_Button9{
            height: 20px;
            width: 145px;
            margin-right: 10px;
            font-size: 9px;
            padding-left: 20px;
            margin-top:-30px;
            text-align: left;
            /* justify-content: space-around; */
        }
        .Home_Button3{
            height: 20px;
            width: 125px; 
            font-size: 9px;
            padding-left: 20px;
            margin-right: 0px;
            margin-top: -2px;
        }
        .Home_Button8{
            height: 20px;
            width: 90px; 
            margin-top: 8px;
            font-size: 9px;
            padding-left: 20px;
            margin-right: 0px;
        }
        .Home_Button4{
            height: 20px;
            width: 180px;
            /* margin-left: 0px; */
            font-size: 9px;
            padding-right: 20px;
            margin-top: -100px;
            
        }
        .Home_Button5{
            height: 20px;
            width: 190px;
            margin-left: 30px;
            font-size: 9px;
            padding-right: 20px;
            margin-top: -10px;
        }
        .Home_Button6{
            height: 20px;
            width: 180px;
            margin-left: 30px;
            font-size: 9px;
            padding-right: 20px;
            margin-top: -10px;
        }
        .Home_Button7{
            height: 20px;
            width: 160px;
            margin-left: 40px;
            font-size: 9px;
            padding-right: 20px;
            margin-top: -10px;
         }
    
}
/* @media (min-width: 1300px) {
    .buttons-container {
        width: 75%;
    }
} */
@media (min-width: 1400px) {
    .buttons-container {
        width: 70%;
    }
}
@media (min-width: 1500px) {
    .buttons-container {
        width: 65%;
    }
}
@media (min-width: 1600px) {
    .buttons-container {
        width: 60%;
    }
}

@media (min-width: 1700px) {
    .buttons-container {
        width: 57%;
    }
}
@media (min-width: 1800px) {
    .buttons-container {
        width: 55%;
    }
}
@media (min-width: 1900px) {
    .buttons-container {
        width: 52%;
    }
}
@media (min-width: 2000px) {
    .buttons-container {
        width: 50%;
    }
}
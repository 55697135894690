/* TermsAndConditions.css */
.terms-container {
    padding: 20px;
    margin: 0 auto;
    /* background-color: #07dbdb31; */
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    max-width: 90%; /* Adjusts container width for responsiveness */
}
.terms-and-conditions{
    background-color: #09e8e81d;
    margin-top: 5%;
}
/* H1 - main heading */
.terms-container-h1 {
    text-align: center;
    margin-bottom: 20px;
    font-size: 3rem;
    color: #970d99;
    padding: 10px;
    font-family: "Montserrat", sans-serif !important;
}

/* H2 - subheadings */
.terms-container-h2 {
    margin-top: 20px;
    font-size: 1.8rem;
    color: #970d99;
    padding: 10px;
    font-family: "Montserrat", sans-serif !important;
}

/* Paragraphs */
.terms-container-paraaaaa {
    margin: 10px 0;
    line-height: 1.6;
    color: #666;
    font-size: 1rem;
    /* padding: 50px; */
    margin-left: 10%;
    width: 80%;
    font-family: "Montserrat", sans-serif;
}

/* Media Queries for responsiveness */

/* Smaller devices (max-width: 768px) */
@media (max-width: 768px) {
    .terms-container-h1 {
        font-size: 2.5rem; /* Smaller font size for smaller screens */
    }
    
    .terms-container-h2 {
        font-size: 1.5rem;
    }
    
    .terms-container-p {
        font-size: 0.9rem; /* Adjust paragraph font size */
    }
    
    .terms-container {
        padding: 15px; /* Reduce padding for smaller screens */
    }
    .terms-and-conditions{
        margin-top: 10%;
    }
}

/* Extra small devices (max-width: 480px) */
@media (max-width: 480px) {
    .terms-container-h1 {
        font-size: 1.5rem;
    }

    .terms-container-h2 {
        font-size: 1.5rem;
    }

    .terms-container-p {
        font-size: 0.85rem; /* Further adjust for very small screens */
    }
    
    .terms-container {
        padding: 10px;
    }
    .terms-and-conditions{
        margin-top: 20%;
    }
}
